<script>
export default {
  name: 'ManagementSurveyEditQuestions',
  components: {
    Action: () => import('@/components/general/Action'),
    InputField: () => import('@/components/general/InputField'),
    Radio: () => import('@/components/general/Radio'),
    Icon: () => import('@/components/general/Icon'),
    SelectField: () => import('@/components/general/SelectField'),
    ValidationMessage: () => import('@/components/general/ValidationMessage')
  },

  props: {
    question: {
      type: Object,
      required: true
    },

    questionIndex: {
      type: Number,
      required: true
    },

    validations: {
      type: Object
    }
  },

  data () {
    return {
      isExpanded: false,
      formData: {
        id: null,
        surveyQuestion: [],
        questionTypes: [
          {
            value: 'checkbox',
            text: this.$t('management.survey.edit:question.multiple')
          },
          {
            value: 'text',
            text: this.$t('management.survey.edit:question.open')
          },
          {
            value: 'slide',
            text: this.$t('management.survey.edit:question.scale')
          },
          {
            value: 'radio',
            text: this.$t('management.survey.edit:question.single')
          }
        ],

        mandatoryOptions: [
          {
            label: this.$t('global:yes'),
            value: true
          },
          {
            label: this.$t('global:no'),
            value: false
          }
        ]
      }
    }
  },

  computed: {
    isNps () {
      return [
        'nps',
        'nps_motivo'
      ].includes(this.question.name)
    }
  },

  watch: {
    'formData.surveyQuestion.data.max' () {
      if (this.formData.surveyQuestion.data.min >= this.formData.surveyQuestion.data.max) {
        this.formData.surveyQuestion.data.min = this.formData.surveyQuestion.data.max - 1
      }
    },

    'formData.surveyQuestion': {
      immediate: true,
      deep: true,
      handler () {
        if (!this.formData.surveyQuestion || this.formData.surveyQuestion.length === 0) return
        typeof this.validations.$touch === 'function' && this.validations.$touch()

        this.$emit('updateQuestion', {
          question: this.formData.surveyQuestion,
          index: this.questionIndex
        })
      }
    }
  },

  created () {
    this.isExpanded = !this.isNps
    this.formData.surveyQuestion = { ...this.question }
  },

  methods: {
    checkInitValue (index) {
      this.$emit('checkInitValue', index)
    },

    removeQuestion (index) {
      this.$emit('removeQuestion', index)
    },

    addAnswer (question) {
      question.surveyQuestionOption.push({
        name: null,
        value: null,
        ordering: null,
        default: 0
      })
    },

    removeAnswer (question, index) {
      question.surveyQuestionOption.splice(index, 1)
    }
  }
}
</script>

<template>
  <div
    :key="questionIndex"
    class="management-survey-question-widget question"
    :class="{'is-expanded': isExpanded}"
  >
    <h2
      class="question-title"
      @click="isExpanded = !isExpanded"
    >
      {{ $t('management.survey.edit:question.header') + ' ' + (questionIndex + 1) + (formData.surveyQuestion.title ? ': ' : '') }} <span
        v-if="formData.surveyQuestion.title"
        class="question-title--content"
      >{{ formData.surveyQuestion.title }}</span> <span
        v-if="isNps"
        class="header-extra"
      > - NPS</span>
      <span
        v-if="isNps"
        v-tooltip="{
          content: $t('management.survey.edit:questions.nps.tooltip'),
          placement: 'top-start',
          classes: ['nps-tooltip'],
          offset: 0,
          arrowSelector: 'nps-tooltip_arrow'
        }"
        class="question-mark header-extra"
      > <Icon
        name="help"
        wrapper
        class="icon-info"
        size="small"
      /></span>
      <Icon
        :name="isExpanded ? 'arrow_drop_up' : 'arrow_drop_down'"
        wrapper
        class="icon-arrow"
        size="large"
      />
    </h2>

    <Transition
      appear
      name="toggling"
    >
      <div
        v-show="isExpanded"
        class="question-content"
      >
        <SelectField
          v-model="formData.surveyQuestion.type"
          :label="$t('management.survey.edit:question.types')"
          :items="formData.questionTypes"
          :disabled="formData.surveyQuestion.fixed == 1"
          :validation="validations.type"
        />

        <InputField
          v-model="formData.surveyQuestion.title"
          :label="$t('management.survey.edit:question.title')"
          :counter="100"
          :disabled="formData.surveyQuestion.fixed == 1"
          :validation="validations.title"
        />

        <h2
          v-if="['checkbox', 'radio'].includes(formData.surveyQuestion.type)"
          class="question-answers-title"
        >
          {{ $t('global:form.management.survey.questions.answers') }}
        </h2>

        <!--
              -- Múltipla escolha
              -->
        <template v-if="formData.surveyQuestion.type === 'checkbox'">
          <ul class="question-answers-items">
            <li
              v-for="(answer, answerIndex) in formData.surveyQuestion.surveyQuestionOption"
              :key="answerIndex"
              class="question-answers-item flex-row"
            >
              <InputField
                v-model="answer.name"
                :label="`Item ${answerIndex + 1}`"
                :counter="100"
                :disabled="formData.surveyQuestion.fixed == 1"
                :validation="validations.surveyQuestionOption.$each[answerIndex].name"
              />

              <Action
                v-if="!formData.surveyQuestion.fixed"
                :text="$t('global:form.management.survey.questions.answer.remove')"
                icon="delete"
                icon-right
                flat
                type="button"
                @click="removeAnswer(formData.surveyQuestion, answerIndex)"
              />
            </li>
          </ul>
          <div
            v-if="formData.surveyQuestion.surveyQuestionOption.length < 1"
            class="question-answers-validation"
          >
            <ValidationMessage
              :validation="validations.surveyQuestionOption"
            />
          </div>
          <Action
            v-if="!formData.surveyQuestion.fixed"
            :text="$t('global:form.management.survey.questions.add.answer')"
            primary
            type="button"
            @click="addAnswer(formData.surveyQuestion)"
          />
        </template>

        <!--
              -- Escala deslizante
              -->
        <template v-else-if="formData.surveyQuestion.type === 'slide'">
          <div class="question-values">
            <InputField
              v-model="formData.surveyQuestion.data.min"
              :label="$t('global:form.management.survey.questions.initial.value')"
              :min="0"
              :max="formData.surveyQuestion.data.max - 1"
              type="number"
              :disabled="formData.surveyQuestion.fixed == 1"
              :validation="validations.data.min"
            />

            <InputField
              v-model="formData.surveyQuestion.data.max"
              :label="$t('global:form.management.survey.questions.final.value')"
              :min="5"
              :max="100"
              type="number"
              :disabled="formData.surveyQuestion.fixed == 1"
              :validation="validations.data.max"
              @input="checkInitValue(questionIndex)"
            />
          </div>
        </template>

        <!--
              -- Escolha única
              -->
        <template v-else-if="formData.surveyQuestion.type === 'radio'">
          <ul class="question-answers-items">
            <li
              v-for="(answer, answerIndex) in formData.surveyQuestion.surveyQuestionOption"
              :key="answerIndex"
              class="question-answers-item flex-row"
            >
              <InputField
                v-model="answer.name"
                :label="`Item ${answerIndex + 1}` + (answerIndex === 0 ? ` - ${$t('global:form.management.survey.questions.standard')}` : '')"
                :counter="100"
                :disabled="formData.surveyQuestion.fixed == 1"
                :validation="validations.surveyQuestionOption.$each[answerIndex].name"
              />

              <Action
                v-if="!formData.surveyQuestion.fixed"
                :text="$t('global:form.management.survey.questions.answer.remove')"
                icon="delete"
                icon-right
                flat
                type="button"
                @click="removeAnswer(formData.surveyQuestion, answerIndex)"
              />
            </li>
          </ul>
          <div
            v-if="formData.surveyQuestion.surveyQuestionOption.length < 1"
            class="question-answers-validation"
          >
            <ValidationMessage
              :validation="validations.surveyQuestionOption"
            />
          </div>
          <Action
            v-if="!formData.surveyQuestion.fixed"
            :text="$t('global:form.management.survey.questions.add.answer')"
            primary
            type="button"
            @click="addAnswer(formData.surveyQuestion)"
          />
        </template>

        <div class="question-bottom">
          <template v-if="formData.surveyQuestion.type === 'radio'">
            <Icon name="info" />
            <h4>{{ $t('global:form.management.survey.questions.always.mandatory') }}</h4>
          </template>
          <template v-else>
            <h3><b>{{ $t('global:form.management.survey.questions.mandatory') }}?</b></h3> <Radio
              v-model="formData.surveyQuestion.required"
              :items="formData.mandatoryOptions"
              horizontal
              :disabled="formData.surveyQuestion.fixed == 1"
            />
          </template>
          <Action
            v-if="!formData.surveyQuestion.fixed"
            :text="$t('global:form.management.survey.questions.remove')"
            primary
            type="button"
            @click="removeQuestion(questionIndex)"
          />
        </div>
      </div>
    </Transition>
  </div>
</template>

<style src="@/assets/styles/themes/default/management.css"></style>
